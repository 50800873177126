import _get from "lodash/get";
import * as TYPES from "./authActionTypes";

const defaultState = {
	authBusy: false,
	preCheck: false,
	token: null,
	profile: null,
	driver: null,
	business: null,
	isOwner: false,
	isAdmin: false,
	isPartner: false,
	isDispacher: false,
	isDriver: false,
	isBroker: false,
	isAccountant: false,
	isMechanic: false,
	isGuest: false,
	countLimit: 10,
	businessStyles: {},
	businessPayables: {},
	businessReceivables: {},
	accessTokens: [],
	bucketInfo: {},
	settingsProfile: {
		fontSize: "medium"
	},
	allScopes: []
};

export default function auth(state = defaultState, action) {
	switch (action.type) {
		case TYPES.PROFILE:
			const userRole = _get(action, "value.role", {});
			const customRole = _get(action, "value.customaccess");
			const fontSize = _get(action, "value.settings.fontSize", "small");
			const isSmall = fontSize === "small";
			// const isMedium = fontSize === "medium";
			const isLarge = fontSize === "large";

			const osritCssId = isLarge ?
				"osritLarge" :
				isSmall ?
				"osritSmall" :
				"osritMedium";

			try {
				document.body.id = osritCssId;
			} catch (e) {
				console.log(e);
			}

			return {
				...state,
				isFullOwner: userRole === "owner" && customRole !== "surrogate",
					isOwner: userRole === "owner",
					isAdmin: userRole === "stadmin",
					isPartner: userRole === "partner",
					isDispacher:
					userRole === "dispacher" ||
					(userRole === "owner" && customRole === "surrogate"),
					isDriver: userRole === "driver",
					isBroker: userRole === "broker",
					isAccountant: userRole === "accountant",
					isManager: userRole === "manager",
					isMechanic: userRole === "mechanic",
					isGuest: userRole === "guest",
					isBilling: userRole === "owner" || userRole === "accountant",
					countLimit: _get(action, "value.loadsperpage", 10),
					profile: action.value,
					osritCssId
			};
		case TYPES.FIREBASE_TOKEN:
			return {
				...state,
				firebaseToken: action.value
			};
		case TYPES.DRIVER:
			return {
				...state,
				driver: action.value
			};
		case TYPES.BUSINESS:
			return {
				...state,
				business: action.value
			};
		case TYPES.BUSINESS_STYLES:
			return {
				...state,
				businessStyles: action.value
			};
		case TYPES.BUSINESS_PAYABLES:
			return {
				...state,
				businessPayables: action.value
			};
		case TYPES.BUSINESS_RECEIVABLES:
			return {
				...state,
				businessReceivables: action.value
			};
		case TYPES.AUTH_TOKEN:
			return {
				...state,
				token: action.value
			};
		case TYPES.AUTH_BUSY:
			return {
				...state,
				authBusy: action.value
			};
		case TYPES.PRE_CHECK:
			return {
				...state,
				preCheck: action.value
			};
		case TYPES.USERS:
			return {
				...state,
				users: action.value
			};
		case TYPES.ACCESS_TOKENS:
			return {
				...state,
				accessTokens: action.value
			};
		case TYPES.BUCKET_INFO:
			return {
				...state,
				bucketInfo: action.value
			};
		case TYPES.ALL_SCOPES:
			return {
				...state,
				allScopes: action.value
			};
		default:
			return state;
	}
}
